var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(" 12123 "),
      _c("a-table", {
        attrs: {
          loading: _vm.loading,
          size: _vm.tableSize,
          rowKey: "menuId",
          columns: _vm.columns,
          "data-source": _vm.treeList,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
          },
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  _vm.allIcon[text + "Icon"]
                    ? _c("a-icon", {
                        attrs: { component: _vm.allIcon[text + "Icon"] },
                      })
                    : _vm._e(),
                  !_vm.allIcon[text + "Icon"]
                    ? _c("a-icon", { attrs: { type: text } })
                    : _vm._e(),
                ],
                1
              )
            },
          },
          {
            key: "operation",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:recyclable:menu:edit"],
                          expression: "['tenant:recyclable:menu:edit']",
                        },
                      ],
                      on: { click: _vm.test },
                    },
                    [
                      _c("a-icon", { attrs: { type: "edit" } }),
                      _vm._v("编辑 "),
                    ],
                    1
                  ),
                  _c("a-divider", {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["tenant:recyclable:menu:edit"],
                        expression: "['tenant:recyclable:menu:edit']",
                      },
                    ],
                    attrs: { type: "vertical" },
                  }),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["tenant:recyclable:menu:edit"],
                          expression: "['tenant:recyclable:menu:edit']",
                        },
                      ],
                      on: { click: _vm.test },
                    },
                    [
                      _c("a-icon", { attrs: { type: "edit" } }),
                      _vm._v("修改 " + _vm._s(record && record.menuId) + " "),
                    ],
                    1
                  ),
                  record.menuId != 0
                    ? _c("a-divider", {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:recyclable:menu:remove"],
                            expression: "['tenant:recyclable:menu:remove']",
                          },
                        ],
                        attrs: { type: "vertical" },
                      })
                    : _vm._e(),
                  record.menuId != 0
                    ? _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["tenant:recyclable:menu:remove"],
                              expression: "['tenant:recyclable:menu:remove']",
                            },
                          ],
                        },
                        [
                          _c("a-icon", { attrs: { type: "delete" } }),
                          _vm._v("删除 "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }