<!--
 * @Auth: linjituan
 * @Date: 2022-08-03 15:57:42
 * @LastEditors: linjituan
 * @LastEditTime: 2022-08-09 17:33:33
-->
<template>
  <div>
    12123
    <a-table
      :loading="loading"
      :size="tableSize"
      rowKey="menuId"
      :columns="columns"
      :data-source="treeList"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :pagination="false"
    >
      <span slot="icon" slot-scope="text">
        <a-icon :component="allIcon[text + 'Icon']" v-if="allIcon[text + 'Icon']" />
        <a-icon :type="text" v-if="!allIcon[text + 'Icon']" />
      </span>
      <span slot="operation" slot-scope="text, record">
        <a v-hasPermi="['tenant:recyclable:menu:edit']" @click="test"> <a-icon type="edit" />编辑 </a>
        <a-divider type="vertical" v-hasPermi="['tenant:recyclable:menu:edit']" />
        <a v-hasPermi="['tenant:recyclable:menu:edit']" @click="test">
          <a-icon type="edit" />修改 {{ record && record.menuId }}
        </a>
        <a-divider type="vertical" v-if="record.menuId != 0" v-hasPermi="['tenant:recyclable:menu:remove']" />
        <a v-if="record.menuId != 0" v-hasPermi="['tenant:recyclable:menu:remove']"> <a-icon type="delete" />删除 </a>
      </span>
    </a-table>
  </div>
</template>

<script>
import { listMenu } from '@/api/recyclable/menu'

export default {
  components: {},
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '权限名称',
          dataIndex: 'name',
          ellipsis: true,
          width: '15%'
        },
        // {
        //   title: '排序',
        //   dataIndex: 'orderNum',
        //   width: '5%',
        //   align: 'center'
        // },
        {
          title: '权限标识',
          dataIndex: 'updateBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'price',
          customRender: (text, record, index) => {
            return '状态1'
          },
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          ellipsis: true,
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '20%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      treeList: [
        {
          id: 1,
          name: '纸',
          parentId: 0,
          price: 0,
          price2: 0,
          icon: 'recyclable/145150789c7fd21b04f804ddcb8c1c4de1e5f60cc.png',
          status: 0,
          createBy: 'admin',
          createTime: '2022-02-23 12:00:00',
          updateBy: 'raadmin',
          updateTime: '2022-06-15 19:27:06',
          sort: 1,
          iconUrl: null,
          selfSettingPrice: null,
          index: 0,
          children: [
            {
              id: 4,
              name: '花板',
              parentId: 1,
              price: 0.7,
              price2: 0.6,
              icon: 'recyclable/10051793837da864cf0174dc6a24efc96a07af179.png',
              status: 0,
              createBy: 'admin',
              createTime: '2022-02-23 12:00:00',
              updateBy: 'raadmin',
              updateTime: '2022-03-17 17:42:11',
              sort: 2,
              iconUrl: null,
              selfSettingPrice: null,
              index: 0
            },
            {
              id: 2,
              name: '黄板',
              parentId: 1,
              price: 1.1,
              price2: 0,
              icon: 'recyclable/100446257669275ab39c84dd7b1b19b9039a7873c.png',
              status: 0,
              createBy: 'admin',
              createTime: '2022-02-22 12:00:00',
              updateBy: 'raadmin',
              updateTime: '2022-03-17 17:39:52',
              sort: 3,
              iconUrl: null,
              selfSettingPrice: null
            },
            {
              id: 3,
              name: '书本',
              parentId: 1,
              price: 0.9,
              price2: 0,
              icon: 'recyclable/100354923638faa6d2e884659be7ba17649c9b875.png',
              status: 0,
              createBy: 'admin',
              createTime: '2022-02-22 12:00:00',
              updateBy: 'raadmin',
              updateTime: '2022-06-15 19:26:57',
              sort: 4,
              iconUrl: null,
              selfSettingPrice: null,
              isEnd: true
            }
          ]
        },
        {
          id: 6,
          name: '金属',
          parentId: 0,
          price: 0,
          price2: 0,
          icon: 'test',
          status: 0,
          createBy: 'admin',
          createTime: '2022-02-23 12:00:00',
          updateBy: 'raadmin',
          updateTime: '2022-03-28 18:59:13',
          sort: 5,
          iconUrl: null,
          selfSettingPrice: null,
          index: 1,
          children: [
            {
              id: 8,
              name: '铜',
              parentId: 6,
              price: 22,
              price2: 0,
              icon: 'recyclable/100632550d1ca7012e2e0440881bced748df08f10.png',
              status: 0,
              createBy: 'admin',
              createTime: '2022-02-23 12:00:00',
              updateBy: 'raadmin',
              updateTime: '2022-06-15 19:26:34',
              sort: 7,
              iconUrl: null,
              selfSettingPrice: null,
              index: 0
            },
            {
              id: 15,
              name: '不锈钢',
              parentId: 6,
              price: 1.6,
              price2: 0,
              icon: 'recyclable/162015950ea12f5440d6943fdb0ff47a5123711aa.png',
              status: 0,
              createBy: 'xuchi',
              createTime: '2022-03-29 16:20:20',
              updateBy: 'xuchi',
              updateTime: '2022-03-29 16:20:34',
              sort: 9,
              iconUrl: null,
              selfSettingPrice: null
            },
            {
              id: 9,
              name: '铝',
              parentId: 6,
              price: 17,
              price2: 0,
              icon: 'recyclable/100642867ecd84b34ebff48038830b52371ed45b4.png',
              status: 0,
              createBy: 'admin',
              createTime: '2022-02-23 12:00:00',
              updateBy: 'raadmin',
              updateTime: '2022-06-01 15:47:41',
              sort: 10,
              iconUrl: null,
              selfSettingPrice: null
            },
            {
              id: 7,
              name: '铁',
              parentId: 6,
              price: 1.3,
              price2: 0,
              icon: 'recyclable/10062236674fd940c5e9e46ceb6ea6b7ade59dc38.png',
              status: 0,
              createBy: 'admin',
              createTime: '2022-02-23 12:00:00',
              updateBy: 'raadmin',
              updateTime: '2022-03-17 17:42:06',
              sort: 13,
              iconUrl: null,
              selfSettingPrice: null,
              isEnd: true
            }
          ]
        },
        {
          id: 5,
          name: '玻璃',
          parentId: 0,
          price: 0.1,
          price2: 0,
          icon: 'recyclable/10053308331346ddef0824060b931ab52fe95c875.png',
          status: 0,
          createBy: 'admin',
          createTime: '2022-02-23 12:00:00',
          updateBy: 'raadmin',
          updateTime: '2022-06-15 19:26:42',
          sort: 11,
          iconUrl: null,
          selfSettingPrice: '{"defaultPrice":0.2,"minPrice":0,"maxPrice":0.4}',
          index: 2
        },
        {
          id: 14,
          name: '泡沫',
          parentId: 0,
          price: 1.2,
          price2: 0,
          icon: 'recyclable/190054167bd54c93d0cf3418b9e3eb1bf1c7e37f3.png',
          status: 0,
          createBy: 'raadmin',
          createTime: '2022-03-28 19:00:55',
          updateBy: 'raadmin',
          updateTime: '2022-06-15 19:26:41',
          sort: 12,
          iconUrl: null,
          selfSettingPrice: '{}',
          index: 3
        },
        {
          id: 16,
          name: '塑料',
          parentId: 0,
          price: 0,
          price2: 0,
          icon: 'recyclable/1621454385c78ba2a9d374447846cca8ce82ce067.png',
          status: 0,
          createBy: 'xuchi',
          createTime: '2022-03-29 16:21:47',
          updateBy: null,
          updateTime: null,
          sort: 14,
          iconUrl: null,
          selfSettingPrice: null,
          index: 4,
          children: [
            {
              id: 17,
              name: '餐盒',
              parentId: 16,
              price: 0.5,
              price2: 0,
              icon: 'recyclable/1622062904dc4a969afc3426c908f3bc872c02410.png',
              status: 0,
              createBy: 'xuchi',
              createTime: '2022-03-29 16:22:07',
              updateBy: null,
              updateTime: null,
              sort: 15,
              iconUrl: null,
              selfSettingPrice: null,
              index: 0,
              isEnd: true
            }
          ]
        },
        {
          id: 19,
          name: '测试',
          parentId: 0,
          price: 1,
          price2: 0.8,
          icon: 'recyclable/165552236cc9e81c985bd4efb8d56028f28a59a69.png',
          status: 0,
          createBy: 'raadmin',
          createTime: '2022-06-27 16:55:54',
          updateBy: null,
          updateTime: null,
          sort: 16,
          iconUrl: null,
          selfSettingPrice: null,
          index: 5
        }
      ],
      loading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log('selectedRowKeys', selectedRowKeys)
      console.log('selectedRows', selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.userId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    getList() {
      this.loading = true
      listMenu(this.queryParam).then((response) => {
        this.list = this.handleTree(response.rows, 'menuId')
        console.log('this.list', this.list)
        this.loading = false
      })
    },
    test() {
      alert(123)
    }
  }
}
</script>

<style></style>
